@keyframes clouds-loop-1 {
    to {
      background-position: -1000px 0;
    }
  }
.clouds-1 {
    background-image: url("https://apis.coastaltourism.info/cdn/utilities/clouds_233.png");
    animation: clouds-loop-1 90s infinite linear;
}
  
  @keyframes clouds-loop-2 {
    to {
      background-position: -1000px 0;
    }
  }
.clouds-2 {
    background-image: url("https://apis.coastaltourism.info/cdn/utilities/clouds_991.png");
    animation: clouds-loop-2 100s infinite linear;
}
  
  @keyframes clouds-loop-3 {
    to {
      background-position: -1579px 0;
    }
  }
.clouds-3 {
    background-image: url("https://apis.coastaltourism.info/cdn/utilities/clouds_55553.png");
    animation: clouds-loop-3 120s infinite linear;
}
  .clouds {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    opacity: 0.2;
    pointer-events: none;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
  }
  
  .clouds-1,
  .clouds-2,
  .clouds-3 {
    background-repeat: repeat-x;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 500px;
  }