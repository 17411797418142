.cny-table-wraper {
    width: 100%;
}

.cny-table-wrapper > .cny-table{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    font-size: 14px;
    background: #fff;
    border-radius: 2px;
    width: 100%;
}

.cny-table-wrapper > table {
    width: 100%;
    text-align: left;
    border-radius: 2px 2px 0 0;
    border-collapse: separate;
    border-spacing: 0;
}

.cny-table th {
    outline: none;    
}

table tbody tr td, table thead tr th {
    padding: 0.8rem 1rem;
    min-height: 50px;
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

.cny-table th.sortable:hover {
    cursor: pointer;
}

.cny-table th.sortable .svg-icon-sort {
    opacity: 0;
    display: inline-block;
}

.cny-table th.sortable:hover .svg-icon-sort {
    opacity: 1;
}

.cny-table th.sortable .sortable-active {
    display: inline-block;
}

.cny-table tbody>tr {
    border-bottom: 1px solid #e4e4e4;
}

.cny-table tbody>tr:hover, .cny-table tbody>tr:hover > .cny-table-cell-fix-left,  .cny-table tbody>tr:hover > .cny-table-cell-fix-right {
    background: #F5F5F5 0% 0% no-repeat padding-box;
}

.cny-table-cell-fix-left, .cny-table-cell-fix-right {
    position: -webkit-sticky !important;
    position: sticky !important;
    z-index: 10;
    background: #fff;
}

.cny-table-cell-section-fix-left, .cny-table-cell-section-fix-right {
    position: -webkit-sticky !important;
    position: sticky !important;
    z-index: 10;
    background: #F5F5F5;
}