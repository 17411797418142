.select-base {
    @apply w-full relative inline-block;
  }

  .select-base div.select-base-input{
    @apply w-full border border-gray-300 focus:outline-none focus:ring-1 py-2 px-3 cursor-pointer
  }

  .select-base input.select-base-input {
    @apply w-full border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-100 focus:border-blue-500 hover:border-blue-400 py-2 px-3
  }

  .select-base span.select-title{
    /* @apply text-black absolute left-0 top-2; */

    user-select: none;
  }

  .select-base .select-base-selector {
    @apply relative bg-white;

    /* border: 1px solid #d9d9d9; */
    border-radius: 2px;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
  }

  .select-base .select-base-selector .select-selection-search {
    @apply w-full;
/* 
    right: 25px;
    inset: 0 11px; */
  }

  .select-base .select-base-selector .select-selection-item {
    @apply absolute;

    padding-left: 13px;
    top: 0;
    left: 0;
    line-height: 40px;
    transition: all .3s;
    flex: 1;
    overflow: hidden;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .select-base .select-hide-label {
    z-index: -9999;
  }

  .select-base .select-base-selector span.select-icon-suffix {
    @apply absolute right-2 top-2 z-10 bg-transparent text-gray-500 text-center items-center ;
  }

  .select-base .select-base-selector div.select-base-option {
    @apply w-full absolute z-[11]
  }

  .select-base-option div.select-base-option-item {
    @apply block 
    relative
    min-h-[32px]
    py-[8px]
    px-[12px]
    text-[#000000d9]
    text-[14px]
    cursor-pointer
    hover:bg-[#F5F5F5];

    line-height: 22px;
    transition: background .3s ease 
  }

  .select-base-option div.select-base-option-item-selected {
    @apply block 
    relative
    min-h-[32px]
    py-[8px]
    px-[12px]
    text-[#000000d9]
    text-[14px]
    font-semibold
    cursor-pointer
    bg-blue-100;

    line-height: 22px;
    transition: background .3s ease 
  }

  .select-item-option-content {
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .select-base-option div.select-base-option-option {
    @apply flex;
  }

  .select-base-option div.select-base-option-item-addon {
    @apply block 
    relative
    min-h-[32px]
    py-[8px]
    px-[12px]
    text-[#000000d9]
    text-[14px]
    font-semibold
    cursor-pointer
    bg-white;

    line-height: 22px;
    transition: background .3s ease 
  }