@font-face {
    font-family: 'prompt-regular';
    src: url('./Prompt/Prompt-Regular.ttf')
}

@font-face {
    font-family: 'prompt-light';
    src: url('./Prompt/Prompt-Light.ttf')
}

@font-face {
    font-family: 'prompt-medium';
    src: url('./Prompt/Prompt-Medium.ttf')
}

@font-face {
    font-family: 'prompt-bold';
    src: url('./Prompt/Prompt-Bold.ttf')
}

@font-face {
    font-family: 'prompt-iltalic';
    src: url('./Prompt/Prompt-Italic.ttf')
}

@font-face {
    font-family: 'chonburi';
    src: url('./Chonburi/Chonburi-Regular.ttf')
}

.prompt-light {
    font-family: prompt-light;
}