@tailwind base;

@tailwind components;

@tailwind utilities;

/* Custom App Syle */
@import "./@utilities/styles/style.css";
/* Font Tage */
@import "./@utilities/fonts/fontface.css";
/* Background CSS */
@import "./@utilities/styles/background.css";
/* Wind CSS */
@import "./@utilities/styles/wind.css";
@import "./@utilities/styles/cloud.css";
@import "./@utilities/styles/odometer.css";
@import "./@utilities/styles/selectserch.css";
@import "./@utilities/styles/table.css";


*, body {
    font-family: 'prompt-regular'
}